export enum SupportLanguages {
  Chinese = "zh",
  Chinese_Traditional = "zh-HK", // 2022-11-28 新增语言
  English = "en",
  French = "fr",
  German = "de",
  Italian = "it",
  Spanish = "es",
  // Danish = 'da',
  Portuguese_Portugal = "pt",
  Dutch = "nl", // 2022-11-28 新增语言
  Swedish = "sv", // 2022-11-28 新增语言

  // 2023-11-28 新增语言
  Danish = "da",
  Czech = "cs",
  Norwegian = "no",
  Polish = "pl",
  Finnish = "fi",
  Turkish = "tr",
  Thai = "th",
  Japanese = "ja",
  Korean = "ko",
  Portuguese_Brazil = "pt-BR",
}
