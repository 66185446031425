import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { i18nextPlugin } from "translation-check"

import { resources } from "@/i18n/resource"
import UrlUtils from "@/utils/UrlUtils"

i18n
  .use(i18nextPlugin)

// load translation using http -> see /public/locales
// learn more: https://github.com/i18next/i18next-http-backend
// .use(Backend)

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  .init({
    debug: import.meta.env.MODE !== "production",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: UrlUtils.get("lang", "en"),
    load: "all", // 加载策略，原本是 languageOnly
    ns: ["menu", "common", "footer", "dashboard", "orders", "tracking_page", "settings", "account", "integration", "get_help", "email_edit", "analytics", "add_ons", "delivery_guarantee"],
    resources: resources,
  })

export default i18n
export const { t } = i18n
