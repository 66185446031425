import qs from "qs"

export default class UrlUtils {
  public static get(param: string, _default: any = null): string | any {
    return <string>qs.parse(window.location.search, { ignoreQueryPrefix: true })[param] ?? _default
  }

  public static removeQueryParam(key: string) {
    let url = window.location.href
    const urlParts = url.split("?")

    if (urlParts.length >= 2) {
      const prefix = encodeURIComponent(key) + "="
      const queryStrings = urlParts[1].split("&")

      for (let i = queryStrings.length; i-- > 0;) {
        if (queryStrings[i].lastIndexOf(prefix, 0) !== -1) {
          queryStrings.splice(i, 1)
        }
      }

      url = urlParts[0] + (queryStrings.length > 0 ? "?" + queryStrings.join("&") : "")
    }

    window.history.replaceState({}, document.title, url)
  }

  static objectToQueryString(obj: Record<any, any>) {
    const keyValuePairs = []

    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key) && obj[key] !== null && obj[key] !== "") {
        keyValuePairs.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
      }
    }
    return keyValuePairs.join("&")
  }

  static setSearchByKey(key: string, value: string) {
    // 获取当前查询参数
    const queryParams = new URLSearchParams(window.location.search)

    // 获取特定参数的值
    let paramValue = queryParams.get(key)

    // 修改参数值
    paramValue = value

    // 更新修改后的值
    queryParams.set(key, paramValue)

    // 构建新的URL
    const newUrl = window.location.pathname + "?" + queryParams.toString()

    // 更新window.location.search
    window.history.replaceState({}, "", newUrl)
  }

  public setParams(url: string, key: string, value: string) {
    if (!url) return url
    const inputUrl = new URL(url)
    const inputParams = new URLSearchParams(inputUrl.search)

    inputParams.set(key, value)

    return inputUrl + "?" + inputParams.toString()
  }
}
